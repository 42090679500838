import React from 'react';
import styles from './AddWidgetButton.module.scss';

const AddWidgetIcon = () => (
	<svg
		width="24px"
		height="24px"
		viewBox="0 0 24 24"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<g
			id="Desktop-designs"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<g
				id="Dashboard-Page-(new)"
				transform="translate(-653.000000, -12.000000)"
				stroke="#979797"
			>
				<g
					id="Add-Widget-Icon"
					transform="translate(653.000000, 12.000000)"
				>
					<rect
						id="Rectangle"
						x="0.5"
						y="0.5"
						width="23"
						height="23"
						rx="4"
					></rect>
					<path
						d="M13,6.5 L13,11 L17.5,11 L17.5,13 L13,13 L13,17.5 L11,17.5 L11,12.9988889 L6.5,12.9998889 L6.5,10.9998889 L11,10.9988889 L11,6.5 L13,6.5 Z"
						id="Combined-Shape"
					></path>
				</g>
			</g>
		</g>
	</svg>
);

// Displays an add widget button if the current route is the dashboard page
const AddWidgetButton = ({ route, onClick }) => {
	if (route !== '/dashboards/[id]') return null;
	return (
		<div
			id="add-widget-button"
			className={styles['widget-button']}
			onClick={onClick}
		>
			<AddWidgetIcon />
			<span>Add widget</span>
		</div>
	);
};

export default AddWidgetButton;
