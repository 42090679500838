import React from 'react';
import { Input, Button } from '@anephenix/ui';
import styles from './AddWidgetForm.module.scss';

const AddWidgetForm = () => (
	<div id={styles['add-widget-form']}>
		<form>
			<label htmlFor="name">Name</label>
			<Input type="text" name="name" placeholder="Name" />
			<Button
				className="button theme-default green-one"
				role="create"
				text="Create"
			/>
		</form>
	</div>
);

export default AddWidgetForm;
