// Dependencies
import React, { useState, Component, useEffect } from 'react';
import Head from 'next/head';
import { Page, NavBar } from '@anephenix/ui';
import { Provider, observer } from 'mobx-react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { useRouter } from 'next/router';

// Components
import Notification from '../src/components/notification/Notification';
import ErrorNotification from '../src/components/error-notification/ErrorNotification';
import AddWidgetButton from '../src/components/add-widget-button/AddWidgetButton';
import AddWidgetForm from '../src/components/add-widget-form/AddWidgetForm';
import Overlay from '../src/components/overlay/Overlay';
import Modal from '../src/components/modal/Modal';
import Logo from '../src/components/logo/Logo';

// Stores
import { stores } from '../src/stores';
import messageStore from '../src/stores/messageStore';
import errorStore from '../src/stores/errorStore';

// Styling
import '@anephenix/ui/dist/index.css';
import '../styles/index.scss';

// Sentry  configuration
Sentry.init({
	dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
	environment: process.env.NEXT_PUBLIC_APP_ENV,
	integrations: [new Integrations.BrowserTracing()],
	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	tracesSampleRate: 1.0,
});

// Small component for the Account
const AccountPlaceholder = () => <div id="account-placeholder">PJ</div>;

// Used to handle the Sentry error capturing
class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { eventId: null, hasError: false };
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		Sentry.withScope((scope) => {
			scope.setExtras(errorInfo);
			const eventId = Sentry.captureException(error);
			this.setState({ eventId });
		});
	}

	render() {
		if (this.state.hasError) {
			//render fallback UI
			return (
				<button
					onClick={() =>
						Sentry.showReportDialog({ eventId: this.state.eventId })
					}
				>
					Report feedback
				</button>
			);
		}
		return this.props.children;
	}
}

// THe content placed in the Head section of the page
const HeadContent = () => (
	<>
		<meta name="viewport" content="initial-scale=1.0, width=device-width" />
		<link rel="icon" type="image/svg+xml" href="/favicon.svg" />
		<link rel="alternate icon" href="/favicon.ico" />
		<link rel="mask-icon" href="/favicon.svg" color="#fff" />
		<title>Dashku</title>
	</>
);

// A helper component where notifications and errors are displayed
const Notifications = observer(({ messageStore, errorStore }) => {
	return (
		<>
			{messageStore.message && (
				<Notification
					message={messageStore.message}
					onClick={messageStore.clear}
				/>
			)}
			{errorStore.error && (
				<ErrorNotification
					message={errorStore.error}
					onClick={errorStore.clear}
				/>
			)}
		</>
	);
});

// The main component
function MyApp({ Component, pageProps }) {
	// Detect the route so that we can determine whether to display the add widget button
	const router = useRouter();
	const [overlayEnabled, setOverlayEnabled] = useState(false);
	const [modal, setModal] = useState(null);

	const closeWidgetDialog = () => {
		setOverlayEnabled(false);
		setModal(null);
	};

	const showWidgetDialog = () => {
		setOverlayEnabled(true);
		setModal(
			<Modal title="Add widget" onClose={closeWidgetDialog}>
				<AddWidgetForm />
			</Modal>
		);
	};

	useEffect(() => {}, [overlayEnabled, modal]);

	return (
		<ErrorBoundary>
			<Provider {...stores}>
				<Head>
					<HeadContent />
				</Head>
				<Page>
					<NavBar
						logo={
							<div id="logo-and-account-container">
								<Logo />
								<AddWidgetButton
									route={router.route}
									onClick={showWidgetDialog}
								/>
								<AccountPlaceholder />
							</div>
						}
						links={[]}
						loggedIn={false}
					></NavBar>
					<Component {...pageProps} />
					<Notifications {...{ errorStore, messageStore }} />
					<Overlay show={overlayEnabled}>{modal}</Overlay>
				</Page>
			</Provider>
		</ErrorBoundary>
	);
}

export default observer(MyApp);
