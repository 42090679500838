import React from 'react';
import styles from './Modal.module.scss';

const Modal = ({ title, onClose, children }) => (
	<div id={styles.modal}>
		<div id={styles['modal-heading']}>
			<div id={styles['modal-title']}>{title}</div>
			<div id={styles['modal-close']} onClick={onClose}>
				&times;
			</div>
		</div>
		<div id={styles['modal-content']}>{children}</div>
	</div>
);

export default Modal;
